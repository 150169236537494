import React, { useEffect } from 'react';
import { ToastProvider, useToasts } from 'react-toast-notifications';

const ToastMessage = ({ trigger, message, type }) => {
  const { addToast } = useToasts();

  useEffect(() => {
    if (trigger) {
      addToast(message, {
        appearance: type,
        autoDismiss: true,
      });
    }
  }, [trigger, addToast, message, type]);

  return null;
};

const SimpleToast = ({ trigger, message, type }) => {
  return (
    <ToastProvider>
      <ToastMessage trigger={trigger} message={message} type={type} />
    </ToastProvider>
  );
};

export default SimpleToast;
