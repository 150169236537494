//import React from "react";
import { CCard, CCardBody, CCol, CContainer, CRow } from "@coreui/react";
import { handleRazorPayPayment } from "../../util/payment-gateway";
import { useHistory } from "react-router";
import Logofull from "../../assets/icons/logo-large.png";
import React, { useState } from "react";
import { CInput } from "@coreui/react";

const VendorGSTOrder = ({ userInfo, setCurrentComponent }) => {
  const history = useHistory();

  const handlePaymentSuccess = (result) => {
    if (result.data.msg === "success") {
      setCurrentComponent("vendor-order-payment-sucess");
      history.push("/vendor-order-payment-sucess", {
        rzpPaymentId: result?.data?.rzpPaymentId,
        invoiceId: result?.data?.invoiceId,
        amount: userInfo?.amount,
      });
    }
  };

  // billing details
  const [name, setName] = useState(userInfo?.full_name);
  const [email, setEmail] = useState(userInfo?.email);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [pincode, setPincode] = useState("");
  const [contactNo, setContactNo] = useState(userInfo?.phone_number);

  return (
    <>
      <div className="">
        <div className="c-app c-default-layout flex-row no-card-bg align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <div className="col-xl-10">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    <div className="p-2">
                      <a href="https://finsights.biz/" target="_blank"><img
                        src={Logofull}
                        className="mb-3 c-sidebar-brand-full mobile-logo-width img-fluid"
                      />{" "}
                      </a>
                    </div>
                  </div>
                </div>
                <hr className="hr-double-dashed-border mt-3 mb-3"></hr>
                <div className="card border-0 b-r-10 mb-0">
                  <div className="card-body p-3">
                    <div className="row">
                      <CCol xl="5" lg="5" md="12" sm="12">
                        <CCard className="border-0  b-r-10 mb-2 light-login-bg">
                          <CCardBody className="p-4">
                            <div className="card border-0 no-card-bg mb-0">
                              <div className="card-header no-card-bg pl-0 pr-0 thin-1px-border pt-0">
                                <div className="row">
                                  <div className="col-xl-12 col-lg-12 col-md-12">
                                    <h5 className="bold-label mb-0">
                                      Order Summary
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body p-0 pt-3">
                                <div className="card bg-white border-0 b-r-10">
                                  <div className="card-body p-3">
                                    <div className="statistic d-flex align-items-center no-card-bg has-shadow">
                                      <div className="icon-lg bg-light-icon bg-white">
                                        <i className="fas fa-book text-dark"></i>
                                      </div>
                                      <div className="text">
                                        <h5 className="mb-2 font-size-12 bold-label">
                                          Vendor GSTR1 Compliance Report{" "}
                                          <i className="fas fa-check-circle text-primary"></i>
                                        </h5>
                                        <h5 className="mb-0">
                                          <i className="fas fa-rupee-sign"></i>{" "}
                                          <span className="bold-label">1</span>{" "}
                                          /
                                          <span className="font-size-12 status-font">
                                            {" "}
                                            Ledger
                                          </span>
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-xl-4 col-lg-4 col-md-4 col-12 mobile-bottom">
                                    <h6 className="bold-label font-size-10 mb-0">
                                      Email Address
                                    </h6>
                                  </div>
                                  <div className="col-xl-8 col-lg-8 col-md-8 col-12 text-right mobile-text-left">
                                    <h6 className="bold-label font-size-12 mb-0 overflow-elipsis" title={userInfo?.email}>
                                      {userInfo?.email}
                                    </h6>
                                  </div>
                                </div>
                                <hr className="hr-double-dashed-border mt-2 mb-3"></hr>
                                <div className="row">
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom">
                                    <h6 className="bold-label font-size-10 mb-0">
                                      Subscription Period
                                    </h6>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right mobile-text-left">
                                    <h6 className="bold-label font-size-12 mb-0">
                                      One Time
                                    </h6>
                                  </div>
                                </div>
                                <hr className="hr-double-dashed-border mt-2 mb-3"></hr>
                                <div className="row">
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                    <h6 className="bold-label font-size-10 text-primary mobile-bottom">
                                      Total
                                    </h6>
                                  </div>
                                  <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right  mobile-text-left">
                                    <h6 className="bold-label font-size-12 text-primary overflow-elipsis">
                                      Rs {userInfo?.amount}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CCardBody>
                        </CCard>
                      </CCol>
                      <CCol xl="7" lg="7" md="12" sm="12">
                        <div className="card b-r-10 border-0 mb-2">
                          <div className="card-body p-4">
                            <div className="card border-0 mb-0">
                              <div className="card-header no-card-bg  thin-1px-border pl-0 pr-0 pt-0">
                                <div className="row">
                                  <div className="col-xl-12 col-lg-12 col-md-12">
                                    <h5 className="bold-label mb-0">
                                      Billing Information
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body p-0 pt-1">
                                <div className="row mb-2">
                                  <div className="col-12">
                                    <div className="row mt-2">
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <label className="mb-1 status-font font-size-12 normal-demo-text">
                                          Name
                                        </label>
                                        <CInput
                                          type="text"
                                          placeholder="Full Name"
                                          className="form-control  rounded-pil light-login-b form-control-lg  custom-input"
                                          value={name}
                                          onInput={(e) =>
                                            setName(e.target.value)
                                          }
                                        ></CInput>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <label className="mb-1 status-font font-size-12 normal-demo-text">
                                          Email Address
                                        </label>
                                        <CInput
                                          type="text"
                                          placeholder="Email Address"
                                          className="form-control  rounded-pil light-login-b form-control-lg custom-input"
                                          value={email}
                                          onInput={(e) =>
                                            setEmail(e.target.value)
                                          }
                                        ></CInput>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-2">
                                  <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                    <div className="row">
                                      <div className="col-xl-16 col-lg-6 col-md-6 col-12">
                                        <label className="mb-1 status-font font-size-12 normal-demo-text">
                                          Address 1
                                        </label>
                                        <textarea
                                          rows="3"
                                          placeholder="Address ..."
                                          className="form-control  rounded-pil light-login-b form-control-lg  custom-input"
                                          value={address1}
                                          onInput={(e) =>
                                            setAddress1(e.target.value)
                                          }
                                        ></textarea>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                        <label className="mb-1 status-font font-size-12 normal-demo-text">
                                          Address 2
                                        </label>
                                        <textarea
                                          rows="3"
                                          placeholder="Address ..."
                                          className="form-control rounded-pil light-login-b form-control-lg  custom-input"
                                          value={address2}
                                          onInput={(e) =>
                                            setAddress2(e.target.value)
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mb-4">
                                  <div className="col-12">
                                    <div className="row">
                                      <div className="col-6">
                                        <label className="mb-1 status-font font-size-12 normal-demo-text">
                                          Pin Code
                                        </label>
                                        <CInput
                                          type="text"
                                          placeholder="Pin Code"
                                          maxLength="10"
                                          className="form-control rounded-pil light-login-b form-control-lg custom-input"
                                          value={pincode}
                                          onInput={(e) =>
                                            setPincode(e.target.value)
                                          }
                                        ></CInput>
                                      </div>
                                      <div className="col-6">
                                        <label className="mb-1 status-font font-size-12 normal-demo-text">
                                          Contact No
                                        </label>
                                        <CInput
                                          type="text"
                                          placeholder="Contact No"
                                          className="form-control rounded-pil light-login-b form-control-lg custom-input"
                                          value={contactNo}
                                          onInput={(e) =>
                                            setContactNo(e.target.value)
                                          }
                                        ></CInput>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </CCol>
                    </div>
                    <hr className="hr-double-dashed-border mt-2 mb-2"></hr>
                    <div className="row mt-3">
                      <div className="col-12 text-center">
                        <button
                          className="btn btn-dark btn-lg status-font shadow-none"
                          onClick={() =>
                            handleRazorPayPayment({
                              full_name: userInfo.full_name,
                              phone_number: userInfo.phone_number,
                              email: userInfo.email,
                              uuid: userInfo.u_uuid,
                              billingDetails: {
                                name,
                                email,
                                address1,
                                address2,
                                pincode,
                                contactNo,
                              },
                              orderExtension: "customOrder",
                              payAmount: userInfo?.amount,
                              type: "VENDOR_GST_ORDER",
                              success: handlePaymentSuccess,
                              description: "Vendor GSTR1 Compliance Report",
                              uniqueRequestId: userInfo?.uniqueRequestId,
                              tenant: userInfo?.tenant
                            })
                          }
                        >
                          Proceed to Pay <i className="fas fa-rupee-sign"></i>{" "}
                          {userInfo?.amount}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CRow>
          </CContainer>
        </div>
      </div>
    </>
  );
};
export default VendorGSTOrder;
