import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import axios from "axios";
import { useLocation, useHistory } from "react-router-dom";
import CustomAlertOrder from "./components/custom-alert-order/custom-alert-order";
import { ScaleLoader } from "react-spinners";
import { css } from "@emotion/react";

import { Route } from "react-router-dom";
import CustomeAlertPaymentSuccess from "./components/custom-alert-order/custom-alert-payment-success";
import ErrorInfo from "./components/error-info/error-info";
import { BASE_URL } from "./util/constants";
import VendorGSTOrder from "./components/vendor-gst-order/vendor-gst-order";
import VendorGstOrderPaymentSuccess from "./components/vendor-gst-order/vendor-gst-order-payment-success";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const App = () => {
  let query = useQuery();

  // token
  const token = query.get("token");

  const [userInfo, setUserInfo] = useState(null);

  const history = useHistory();

  useEffect(() => {
    getUserDetails();
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const getUserDetails = async () => {
    try {
      const userDetailsRes = await axios.post(
        `${BASE_URL}/sub/decryptUserTokenDetailsForCustomAlerts`,
        {
          token,
        }
      );

      let userInfo = userDetailsRes.data;
      if (userInfo.type === "CUSTOM_ALERTS") {
        setComponentToShow("custom-alerts-order");
        setUserInfo(userInfo);
      } else if (userInfo.type === "VENDOR_GST_ORDER") {
        setComponentToShow("vendor-gst-order");
        setUserInfo(userInfo);
      }
    } catch (e) {
      setComponentToShow("error");
      history.push("/error");
      // alert("Cannot process the request\nPlease try again later");
    }
  };

  const [componentToShow, setComponentToShow] = useState("loader");

  const setCurrentComponent = (type) => {
    setComponentToShow(type);
  };

  useEffect(() => {
    if (componentToShow === "custom-alerts-order") {
      history.push("/custom-alerts-order");
    } else if (componentToShow === "vendor-gst-order") {
      history.push("/vendor-gst-order");
    }
  }, [componentToShow]);

  return (
    <>
      {componentToShow === "loader" && (
        <div className="row">
          <div className="col-xl-6 col-lg-6 offset-xl-3">
            <div className="card border-0 c-app flex-row align-items-center">
              <ScaleLoader
                css={override}
                // size={60 , 5}
                height={50}
                width={5}
                margin={5}
                color={"#f58b3d"}
                loading={componentToShow === "loader"}
              />
            </div>
          </div>
        </div>
      )}

      <Route
        path="/custom-alerts-order"
        component={() => (
          <CustomAlertOrder
            userInfo={userInfo}
            setCurrentComponent={setComponentToShow}
          />
        )}
        exact
      />

      <Route
        path="/vendor-gst-order"
        component={() => (
          <VendorGSTOrder
            userInfo={userInfo}
            setCurrentComponent={setComponentToShow}
          />
        )}
        exact
      />

      <Route
        path="/vendor-order-payment-sucess"
        exact
        component={VendorGstOrderPaymentSuccess}
      />

      <Route
        path="/custom-alert-payment-sucess"
        exact
        component={CustomeAlertPaymentSuccess}
      />

      <Route path="/error" exact component={ErrorInfo} />
    </>
  );
};

export default App;
