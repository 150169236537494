import axios from 'axios';
import { RZP_KEY } from '../config/env-variables';
import logo from '../logo.svg';
import { BASE_URL } from './constants';

const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const handleRazorPayPayment = async ({
  full_name,
  phone_number,
  email,
  uuid,
  success,
  billingDetails,
  orderExtension = 'orders',
  payAmount = 0,
  type = 'CUSTOM_ALERTS',
  uniqueRequestId = '',
  description,
  tenant = null,
  couponCode,
  amountDetails,
}) => {
  if (true) {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const result = await axios.post(`${BASE_URL}/payment/${orderExtension}`, {
      amount: payAmount,
      couponCode,
      amountDetails,
    });

    if (!result) {
      alert('Server error. Are you online?');
      return;
    }

    const { amount, id: order_id, currency, receipt } = result.data;

    const options = {
      key: RZP_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: 'Finsights',
      description: description,
      image:
        'https://finsightsassets.sgp1.cdn.digitaloceanspaces.com/finsights/small-logo-finsights.png',
      order_id: order_id,
      handler: async (response) => {
        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          receiptNo: receipt,
          uuid,
          billingDetails,
          type,
          amount: Number(amount) / 100,
          uniqueRequestId,
          tenant,
          couponCode,
        };

        const result = await axios.post(`${BASE_URL}/payment/success`, data);

        success(result);
      },
      prefill: {
        name: full_name,
        email: email,
        contact: phone_number,
      },
      notes: {
        address: `Dno. 308, 3rd Floor
        BLDG No. A1
        Sect-1, MBP Thane MH 400701 INDIA
        Tel: 8688908877`, // replace with Lotus Insights Address??
      },
      theme: {
        color: '#61dafb',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
};

export { handleRazorPayPayment };
