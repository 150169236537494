import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCol, CContainer, CRow } from '@coreui/react';
import { useHistory, useLocation } from 'react-router';
import Logofull from '../../assets/icons/logo-large.png';
import { BASE_URL, INVOICE_URL } from '../../util/constants';
import Tooltip from '@material-ui/core/Tooltip';

const CustomeAlertPaymentSuccess = () => {
  // read params
  const location = useLocation();
  const history = useHistory();

  const { state } = location;

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [allDetails, setAllDetails] = useState(null);

  useEffect(() => {
    if (state?.rzpPaymentId) {
      fetch(
        `${BASE_URL}/misc/getPaymentAndInvoiceDetails?paymentId=${state?.rzpPaymentId}&invoiceId=${state?.invoiceId}`
      )
        .then((res) => res.json())
        .then((res) => {
          console.log(res);
          if (res) {
            setPaymentDetails(res.payment_details);
            setAllDetails(res);
          }
        });
    } else {
      history.push('/');
    }
  }, [state?.rzpPaymentId]);

  const handleDownloadInvoice = () => {
    // read data from state
    window.open(
      `${INVOICE_URL}/getInvoice?invoiceId=${btoa(allDetails.invoice_id)}`
    );
  };
  const gotoHomePage = () => {
    let subTab = window.open('', 'subTab');
    subTab.focus();
  };
  return (
    <>
      <div className="">
        <div className="c-ap mt-3 c-default-layout flex-row no-card-bg align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol xl="6" lg="6" md="12" sm="12">
                <CCard className="border-0 no-card-bg">
                  <CCardBody className="p-0">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <div className="p-2">
                          <a href="https://finsights.biz/" target="_blank">
                            <img
                              src={Logofull}
                              className="mb-3 c-sidebar-brand-full mobile-logo-width img-fluid"
                            />{' '}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card border-0 mdb-shadow b-r-10">
                      <div className="card-header no-card-bg pl-5 pb-2 pr-5 pt-4 border-0">
                        <div className="close-application d-none d-sm-block">
                          <button
                            className="btn btn-dark status-font shadow-none d-none d-sm-block"
                            onClick={() => window.close()}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                        <div className="row mb-0">
                          <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                            <h1 className="large-font text-success">
                              <i className="fas fa-check-circle"></i>
                            </h1>
                            <h4 className="bold-label mb-0 text-success">
                              Payment Successful
                            </h4>
                            <div className="mt-2 text-center">
                              <button
                                className="btn btn-link text-primary bold-label font-size-14 shadow-none"
                                onClick={handleDownloadInvoice}
                              >
                                <Tooltip title={'Download Invoice'} arrow>
                                  <span>
                                    <i className="fas fa-download"></i> Download
                                  </span>
                                </Tooltip>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-2 pl-4 pr-4 pb-4">
                        <div className="card border-0 mb-0 light-login-bg">
                          <div className="card-body p-3">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-12 mobile-bottom">
                                <h6 className="status-font font-size-10 mb-0">
                                  Payment Type
                                </h6>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-12 text-right mobile-text-left">
                                <h6 className="bold-label font-size-12 mb-0 overflow-elipsis">
                                  {paymentDetails?.method}
                                </h6>
                              </div>
                            </div>
                            <hr className="hr-double-dashed-border mt-1 mb-1"></hr>
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-12 mobile-bottom">
                                <h6 className="status-font font-size-10 mb-0">
                                  Bank
                                </h6>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-12 text-right mobile-text-left">
                                <h6 className="bold-label font-size-12 mb-0 overflow-elipsis">
                                  {paymentDetails?.bank}
                                </h6>
                              </div>
                            </div>
                            <hr className="hr-double-dashed-border mt-1 mb-1"></hr>
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-12 mobile-bottom">
                                <h6 className="status-font font-size-10 mb-0">
                                  Mobile
                                </h6>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-12 text-right mobile-text-left">
                                <h6 className="bold-label font-size-12 mb-0 overflow-elipsis">
                                  {paymentDetails?.contact}
                                </h6>
                              </div>
                            </div>
                            <hr className="hr-double-dashed-border mt-1 mb-1"></hr>
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-12 mobile-bottom">
                                <h6 className="status-font font-size-10 mb-0">
                                  Email Address
                                </h6>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-12 text-right mobile-text-left">
                                <h6 className="bold-label font-size-12 mb-0 overflow-elipsis">
                                  {paymentDetails?.email}
                                </h6>
                              </div>
                            </div>
                            <hr className="hr-double-dashed-border mt-1 mb-1"></hr>
                            {/* <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                            <h6 className="bold-label font-size-10 mb-0">
                              Current Subscription Validity
                            </h6>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right">
                            <h6 className="bold-label font-size-12 mb-0">
                              21/02/2021
                            </h6>
                          </div>
                        </div> */}
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom">
                                <h6 className="status-font font-size-10 mb-0">
                                  Subscription Extended to
                                </h6>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right mobile-text-left">
                                <h6 className="bold-label font-size-12 mb-0 overflow-elipsis">
                                  {allDetails?.sub_period}
                                </h6>
                              </div>
                            </div>
                            <hr className="hr-double-dashed-border mt-1 mb-1"></hr>
                            <div className="row">
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom">
                                <h6 className="status-font font-size-10 text-primary mb-0">
                                  Total
                                </h6>
                              </div>
                              <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right mobile-text-left">
                                <h6 className="bold-label font-size-12 text-primary mb-0  overflow-elipsis">
                                  Rs.{' '}
                                  {Number(state?.total).toLocaleString('en-IN')}
                                </h6>
                              </div>
                            </div>
                            <hr className="hr-double-dashed-border mt-1 mb-1 d-none d-sm-block"></hr>

                            <div className="row mt-3 d-none d-sm-block">
                              <div className="col-12 text-center">
                                <button
                                  onClick={() => gotoHomePage()}
                                  className="btn btn-dark status-font font-size-12 shadow-none"
                                >
                                  <i className="fas fa-undo"></i> Back to Home
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </>
  );
};
export default CustomeAlertPaymentSuccess;
