//import React from "react";
import {
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react';
import { handleRazorPayPayment } from '../../util/payment-gateway';
import { useHistory } from 'react-router';
import Logofull from '../../assets/icons/logo-large.png';
import React, { useEffect, useState } from 'react';
import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CInput,
  CButton,
} from '@coreui/react';
import SimpleToast from '../simple-toast/SimpleToast';
import { BASE_URL } from '../../util/constants';

const CustomAlertOrder = ({ userInfo, setCurrentComponent }) => {
  const history = useHistory();

  const handlePaymentSuccess = (result) => {
    if (result.data.msg === 'success') {
      setCurrentComponent('custom-alert-payment-sucess');
      history.push('/custom-alert-payment-sucess', {
        rzpPaymentId: result?.data?.rzpPaymentId,
        invoiceId: result?.data?.invoiceId,
        total: amountDetails?.total,
      });
    }
  };

  const [primaryRefModal, setprimaryRefModal] = useState(false);
  const onRefModalClose = () => {
    setprimaryRefModal(!primaryRefModal);
  };

  // billing details
  const [name, setName] = useState(userInfo?.full_name);
  const [email, setEmail] = useState(userInfo?.email);
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [pincode, setPincode] = useState('');
  const [contactNo, setContactNo] = useState(userInfo?.phone_number);
  const [gstin, setGstin] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [stateName, setStateName] = useState('');

  const [showToast, setShowToast] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('error');

  const [couponCode, setCouponCode] = useState('');

  const defaultAmounts = {
    amount: 2999,
    gstAmount: 539.82,
    total: 3538.82,
    discountPercent: 0,
    planAmount: 2999,
    discountAmount: 0,
  };

  const [amountDetails, setAmountDetails] = useState(defaultAmounts);

  const toastHandler = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 1000);
  };

  const validateFormAndProceedToHandlePayment = () => {
    if (name && email && gstin && companyName) {
      handleRazorPayPayment({
        full_name: userInfo.full_name,
        phone_number: userInfo.phone_number,
        email: userInfo.email,
        uuid: userInfo.u_uuid,
        billingDetails: {
          name,
          email,
          address1,
          address2,
          pincode,
          contactNo,
          companyName,
          stateName,
          gstin,
        },
        success: handlePaymentSuccess,
        description: 'Subscription for WhatsApp Alerts',
        uniqueRequestId: userInfo?.uniqueRequestId,
        couponCode,
        amountDetails,
      });
    } else {
      setMessageType('error');
      setMessage('Please fill all the fields marked with *');
      toastHandler();
    }
  };

  const checkCouponCodeAndGetAmountAfterDiscount = () => {
    fetch(
      `${BASE_URL}/misc/checkCouponCodeAndGetAmountAfterDiscount?couponCode=${couponCode}`
    )
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (res?.valid) {
          setMessageType('success');
          setMessage('Coupon Code Applied Successfully');
          toastHandler();
          setAmountDetails(res);
        } else {
          // coupon code not valid
          setMessageType('error');
          setMessage('Coupon Code Not Valid');
          toastHandler();
          setAmountDetails(defaultAmounts);
        }
      });
  };

  return (
    <>
      <div className="">
        <div className="gradient-color">
          <div className="c-app c-default-layout flex-row align-items-center no-card-bg login-ill login-bottom-bg">
            <div className="container-fluid max-container-width">
              <SimpleToast
                trigger={showToast}
                message={message}
                type={messageType}
              />
              <CRow className="justify-content-center">
                <CCol xl="12" lg="12" md="12" sm="12">
                  <CCard className="mobile-shadow mt-0 border-0 no-card-bg mb-0">
                    <CCardBody className="mobile-padding">
                      <CCardGroup className="align-items-center">
                        <CCard className="border-0 no-card-bg inside-border-radius">
                          <CCardBody className="text-center p-5 mobile-padding">
                            <CRow className="justify-content-center">
                              <CCol xl="10" lg="12" md="12" sm="12">
                                <div className="p-2">
                                  <a
                                    href="https://finsights.biz/"
                                    target="_blank"
                                  >
                                    <img
                                      src={Logofull}
                                      className="mb-3 c-sidebar-brand-full mobile-logo-width img-fluid"
                                    />{' '}
                                  </a>
                                </div>
                                <div className="card border-0 b-r-10 mb-0">
                                  <div className="card-header no-card-bg p-3 thin-1px-border pt-0">
                                    <div className="row">
                                      <div className="col-xl-12 col-lg-12 col-md-12">
                                        <p className="bold-label mb-0 font-size-16-p">
                                          Order Summary
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-body p-5 pt-3">
                                    <div className="card light-login-bg border-0 b-r-10">
                                      <div className="card-body p-3">
                                        <div className="statistic d-flex align-items-center no-card-bg text-left has-shadow">
                                          <div className="icon-lg bg-light-icon bg-success border-0">
                                            <i className="fab fa-whatsapp text-white fa-1x"></i>
                                          </div>
                                          <div className="text">
                                            <p className="mb-0 font-size-12 bold-label">
                                              Activate Whats App Alerts{' '}
                                              <i className="fas fa-check-circle text-primary"></i>
                                            </p>
                                            <p className="mb-0">
                                              <i className="fas fa-rupee-sign"></i>{' '}
                                              <span className="bold-label">
                                                2,999
                                              </span>{' '}
                                              /
                                              <span className="font-size-12 status-font">
                                                {' '}
                                                User per annum
                                              </span>
                                            </p>
                                            <p className="mb-0 font-size-12 status-font">
                                              ( <i>Exclusive of 18% GST</i> )
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-xl-4 col-lg-4 col-md-4 col-12 mobile-bottom text-left">
                                        <h6 className="bold-label font-size-10 mb-0">
                                          Email Address
                                        </h6>
                                      </div>
                                      <div className="col-xl-8 col-lg-8 col-md-8 col-12 text-right mobile-text-left">
                                        <h6
                                          className="bold-label font-size-12 mb-0 overflow-elipsis"
                                          title={userInfo?.email}
                                        >
                                          {userInfo?.email}
                                        </h6>
                                      </div>
                                    </div>
                                    <hr className="hr-double-dashed-border mt-2 mb-2"></hr>
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom  text-left">
                                        <h6 className="bold-label font-size-10 mb-0">
                                          Current Subscription Validity
                                        </h6>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right mobile-text-left">
                                        <h6 className="bold-label font-size-12 mb-0">
                                          {userInfo?.valid_upto}
                                        </h6>
                                      </div>
                                    </div>
                                    <hr className="hr-double-dashed-border mt-2 mb-2"></hr>
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom text-left">
                                        <h6 className="bold-label font-size-10 mb-0">
                                          Subscription Period
                                        </h6>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right mobile-text-left">
                                        <h6 className="bold-label font-size-12 mb-0">
                                          12 months
                                        </h6>
                                      </div>
                                    </div>
                                    <hr className="hr-double-dashed-border mt-2 mb-2"></hr>
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom  text-left">
                                        <h6 className="bold-label font-size-10 text-primary">
                                          Total
                                        </h6>
                                      </div>
                                      <div className="col-xl-6 col-lg-6 col-md-6 col-12 text-right  mobile-text-left">
                                        <h6 className="bold-label font-size-12 text-primary">
                                          Rs 2,999
                                        </h6>
                                      </div>
                                    </div>

                                    <div className="row">
                                      {/* <div className="col-xl-6 col-lg-6 col-md-6 col-12 mobile-bottom  text-left">
                                        <h6 className="bold-label font-size-10 text-primary">
                                          Coupon Code
                                        </h6>
                                      </div> */}
                                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 text-right  mobile-text-left">
                                        <div class="input-group mb-0 mt-2">
                                          <CInput
                                            type="text"
                                            placeholder="Coupon Code"
                                            className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg custom-input"
                                            value={couponCode}
                                            onChange={(e) =>
                                              setCouponCode(e.target.value)
                                            }
                                          ></CInput>
                                          <div class="input-group-append">
                                            <button
                                              className="btn btn-primary status-font shadow-none"
                                              onClick={
                                                checkCouponCodeAndGetAmountAfterDiscount
                                              }
                                            >
                                              Apply{' '}
                                              <i className="fas fa-chevron-circle-right"></i>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CCol>
                            </CRow>
                          </CCardBody>
                        </CCard>
                        <CCard className="border-0 no-card-bg">
                          <CCardBody className="mobile-padding login-forms p-0">
                            <div className="row justify-content-center">
                              <div className="col-xl-10 col-lg-12 col-md-12">
                                <div className="card b-r-10 border-0 mb-2">
                                  <div className="card-body pl-4 pr-4 pt-1 pb-1">
                                    <div className="card border-0 mb-0">
                                      <div className="card-header no-card-bg border-0 pl-0 pr-0 pt-0 pb-0">
                                        <div className="row">
                                          <div className="col-xl-12 col-lg-12 col-md-12">
                                            <p className="bold-label mb-0 font-size-18-p">
                                              Billing Information
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body p-0 pt-0">
                                        <div className="row mb-1">
                                          <div className="col-12">
                                            <div className="row mt-0">
                                              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Company Name *
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="Company Name"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg  custom-input"
                                                  value={companyName}
                                                  onChange={(e) =>
                                                    setCompanyName(
                                                      e.target.value
                                                    )
                                                  }
                                                ></CInput>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mb-1">
                                          <div className="col-12">
                                            <div className="row mt-0">
                                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Name *
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="Full Name"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg  custom-input"
                                                  value={name}
                                                  onChange={(e) =>
                                                    setName(e.target.value)
                                                  }
                                                ></CInput>
                                              </div>
                                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  GSTIN *
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="GSTIN"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg custom-input"
                                                  value={gstin}
                                                  onChange={(e) =>
                                                    setGstin(e.target.value)
                                                  }
                                                ></CInput>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mb-1">
                                          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <div className="row">
                                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Email Address *
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="Email Address"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg custom-input"
                                                  value={email}
                                                  onChange={(e) =>
                                                    setEmail(e.target.value)
                                                  }
                                                ></CInput>
                                              </div>
                                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Mobile *
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="Mobile"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg custom-input"
                                                  value={contactNo}
                                                  onChange={(e) =>
                                                    setContactNo(e.target.value)
                                                  }
                                                ></CInput>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="row mb-1">
                                          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                                            <div className="row">
                                              <div className="col-xl-16 col-lg-6 col-md-6 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Address 1
                                                </label>
                                                <textarea
                                                  rows="2"
                                                  placeholder="Address ..."
                                                  className="form-control p-2 rounded-pil light-login-b form-control-lg  custom-input"
                                                  value={address1}
                                                  onChange={(e) =>
                                                    setAddress1(e.target.value)
                                                  }
                                                ></textarea>
                                              </div>
                                              <div className="col-xl-6 col-lg-6 col-md-6 col-12">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Address 2
                                                </label>
                                                <textarea
                                                  rows="2"
                                                  placeholder="Address ..."
                                                  className="form-control p-2 rounded-pil light-login-b form-control-lg  custom-input"
                                                  value={address2}
                                                  onChange={(e) =>
                                                    setAddress2(e.target.value)
                                                  }
                                                ></textarea>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="row mb-0">
                                          <div className="col-12">
                                            <div className="row">
                                              <div className="col-6">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  State
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="State"
                                                  maxLength="10"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg custom-input"
                                                  value={stateName}
                                                  onChange={(e) =>
                                                    setStateName(e.target.value)
                                                  }
                                                ></CInput>
                                              </div>
                                              <div className="col-6">
                                                <label className="mb-1 status-font font-size-12 normal-demo-text">
                                                  Pin Code
                                                </label>
                                                <CInput
                                                  type="text"
                                                  placeholder="Pin Code"
                                                  maxLength="10"
                                                  className="form-control p-2 h-35 rounded-pil light-login-b form-control-lg custom-input"
                                                  value={pincode}
                                                  onChange={(e) =>
                                                    setPincode(e.target.value)
                                                  }
                                                ></CInput>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card no-card-bg mb-0 border-0">
                                  <div className="card-body pl-4 pr-4 pt-1 pb-1 mobile-padding">
                                    <div className="card border-0 bg-light mb-1 b-r-10">
                                      <div className="card-body p-3">
                                        <p className="bold-label font-size-12 mb-1">
                                          PAYMENT DETAILS
                                        </p>
                                        <div className="row mb-0">
                                          <div className="col-xl-6 col-md-6 col-6">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              Subscription Plan amount
                                            </p>
                                          </div>
                                          <div className="col-xl-6 col-md-6 col-6 text-right">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              RS.{' '}
                                              {Number(
                                                amountDetails?.planAmount
                                              ).toLocaleString('en-IN')}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row mb-0">
                                          <div className="col-xl-6 col-md-6 col-6">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              Coupon Discount (
                                              <i>
                                                {amountDetails?.discountPercent}{' '}
                                                %
                                              </i>
                                              )
                                            </p>
                                          </div>
                                          <div className="col-xl-6 col-md-6 col-6 text-right">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              RS.{' '}
                                              {Number(
                                                amountDetails?.discountAmount
                                              ).toLocaleString('en-IN')}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row mb-0">
                                          <div className="col-xl-6 col-md-6 col-6">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              Taxable Amount
                                            </p>
                                          </div>
                                          <div className="col-xl-6 col-md-6 col-6 text-right">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              RS.{' '}
                                              {Number(
                                                amountDetails?.amount
                                              ).toLocaleString('en-IN')}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row mb-0">
                                          <div className="col-xl-6 col-md-6 col-6">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              GST @ 18%
                                            </p>
                                          </div>
                                          <div className="col-xl-6 col-md-6 col-6 text-right">
                                            <p className="font-size-12 bold-label mb-0 normal-demo-text">
                                              RS.{' '}
                                              {Number(
                                                amountDetails?.gstAmount
                                              ).toLocaleString('en-IN')}
                                            </p>
                                          </div>
                                        </div>
                                        <hr
                                          className="mt-1 mb-0"
                                          style={{ borderColor: '#e7e7e7' }}
                                        />
                                        <div className="row">
                                          <div className="col-xl-6 col-md-6 col-6">
                                            <p className="font-size-12 bold-label mb-0">
                                              AMOUNT PAYABLE
                                            </p>
                                          </div>
                                          <div className="col-xl-6 col-md-6 col-6 text-right">
                                            <p className="font-size-12 bold-label mb-0">
                                              Rs.{' '}
                                              {Number(
                                                amountDetails?.total
                                              ).toLocaleString('en-IN')}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="row mt-2">
                                  <div className="col-12 text-center">
                                    <button
                                      className="btn btn-dark status-font shadow-none"
                                      onClick={
                                        validateFormAndProceedToHandlePayment
                                      }
                                    >
                                      Proceed to Pay{' '}
                                      <i className="fas fa-chevron-circle-right"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CCardBody>
                        </CCard>
                      </CCardGroup>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </div>
            {/* <div className="container-fluid">
            <CRow className="justify-content-center">
              <div className="col-xl-10">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                    
                  </div>
                </div>
                <hr className="hr-double-dashed-border mt-3 mb-3"></hr>
                <div className="card border-0 b-r-10 mb-0">
                  <div className="card-body p-3">
                    <div className="row">
                      <CCol xl="5" lg="5" md="12" sm="12">
                        <div className="p-2">
                          <a href="https://finsights.biz/" target="_blank"><img
                            src={Logofull}
                            className="mb-3 c-sidebar-brand-full mobile-logo-width img-fluid"
                          />{" "}
                          </a>
                        </div>
                        <CCard className="border-0  b-r-10 mb-2 light-login-bg">
                          <CCardBody className="p-4">
                            
                          </CCardBody>
                        </CCard>
                      </CCol>
                      <CCol xl="7" lg="7" md="12" sm="12">
                        
                      </CCol>
                    </div>
                    <hr className="hr-double-dashed-border mt-2 mb-2"></hr>
                    
                  </div>
                </div>
              </div>
            </CRow>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomAlertOrder;
