import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CContainer, CRow } from "@coreui/react";
import Logofull from "../../assets/icons/logo-large.png";

const ErrorInfo = (props) => {
  return (
    <>
      <div className="">
        <div className="c-app c-default-layout flex-row no-card-bg align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol xl="6" lg="6" md="12" sm="12">
                <CCard className="border-0 no-card-bg">
                  <CCardBody className="p-0">
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                        <div className="p-2">
                          <a href="https://finsights.biz/" target="_blank"><img
                            src={Logofull}
                            className="mb-3 c-sidebar-brand-full mobile-logo-width img-fluid"
                          />{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card border-0 mdb-shadow b-r-10">
                      <div className="card-header no-card-bg pl-5 pb-2 pr-5 pt-4 border-0">
                        <div className="row mb-0">
                          <div className="col-xl-12 col-lg-12 col-md-12 text-center">
                            <h1 className="large-font text-danger">
                              <i class="fas fa-exclamation-circle"></i>
                            </h1>
                            <h4 className="bold-label mb-0 text-danger">
                              Error
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="card-body pt-3 pl-5 pr-5 pb-4">
                        <hr className="hr-double-dashed-border mt-2 mb-2"></hr>
                        <div className="text-center">
                          <span>
                            Request Cannot be processed, Please try again!
                          </span>
                        </div>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      </div>
    </>
  );
};
export default ErrorInfo;
